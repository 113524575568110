// src/components/EditItinerary/FormFields.jsx

import React from 'react';
import { Field } from 'formik';
import { Grid, TextField, MenuItem, Typography, Checkbox } from '@mui/material';

// autmatically calculate date
const calculateCheckoutDate = (startDate, nights) => {
  const checkIn = new Date(startDate);
  checkIn.setDate(checkIn.getDate() + parseInt(nights, 10));
  return checkIn.toISOString().split('T')[0];
};

export const FormFields = ({
  values,
  handleChange,
  setFieldValue,
  allCountries,
  allLocations,
  allVisas,
  currencies,
  dispatch,
}) => (
  <>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="Itinerary name"
        name="itineraryName"
        type="text"
        fullWidth
        className="mb-3"
        required
      />
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="Country"
        name="country"
        type="text"
        fullWidth
        select={true}
        className="mb-3"
        required
        onChange={(e) => {
          setFieldValue('country', e.target.value);
          setFieldValue('visaStatus', false);
        }}
      >
        {allCountries?.data?.map((country) => (
          <MenuItem key={country} value={country}>
            {country}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="Destination"
        name="location"
        type="text"
        fullWidth
        className="mb-3"
        select={true}
        required
      >
        {allLocations?.data
          ?.filter((location) => location.country === values.country)
          .map((location) => (
            <MenuItem key={location._id} value={location.locationName || ''}>
              {location.locationName}
            </MenuItem>
          ))}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="No. of Adults"
        name="numberOfAdults"
        id="numberOfAdults"
        type="number"
        fullWidth
        select={true}
        defaultValue={1}
        className="mb-3"
        required
        onChange={(e) => {
          setFieldValue('numberOfAdults', e.target.value);
          dispatch({
            type: 'UPDATE_PRICES',
            payload: {
              adult: e.target.value,
              child: values.numberOfChildren,
              infant: values.numberOfInfants,
            },
          });
        }}
      >
        {Array.from({ length: 30 }, (_, index) => (
          <MenuItem key={index} value={index}>
            {index}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="No. of Children"
        name="numberOfChildren"
        type="number"
        fullWidth
        select={true}
        className="mb-3"
        required
        onChange={(e) => {
          setFieldValue('numberOfChildren', e.target.value);
          dispatch({
            type: 'UPDATE_PRICES',
            payload: {
              adult: values.numberOfAdults,
              child: e.target.value,
              infant: values.numberOfInfants,
            },
          });
        }}
      >
        {Array.from({ length: 25 }, (_, index) => (
          <MenuItem key={index} value={index}>
            {index}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="No. of Infants"
        name="numberOfInfants"
        type="number"
        fullWidth
        select={true}
        className="mb-3"
        required
        onChange={(e) => {
          setFieldValue('numberOfInfants', e.target.value);
          dispatch({
            type: 'UPDATE_PRICES',
            payload: {
              adult: values.numberOfAdults,
              child: values.numberOfChildren,
              infant: e.target.value,
            },
          });
        }}
      >
        {Array.from({ length: 10 }, (_, index) => (
          <MenuItem key={index} value={index}>
            {index}
          </MenuItem>
        ))}
      </Field>
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        name="tour_start_date"
        type="date"
        fullWidth
        className="mb-3"
        label="Check In"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          const checkInDate = e.target.value;
          setFieldValue('tour_start_date', checkInDate);

          // Calculate and update the checkout date if NoOfNights is available
          if (values.NoOfNights) {
            const checkOutDate = calculateCheckoutDate(
              checkInDate,
              values.NoOfNights,
            );
            setFieldValue('tour_end_date', checkOutDate);
          }
        }}
      />
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        label="Duration in Nights"
        name="NoOfNights"
        type="number"
        fullWidth
        disabled
        className="mb-3"
        onChange={(e) => {
          const nights = e.target.value;
          setFieldValue('NoOfNights', nights);
          if (values.tour_start_date) {
            const checkOutDate = calculateCheckoutDate(
              values.tour_start_date,
              nights,
            );
            setFieldValue('tour_end_date', checkOutDate);
          }
        }}
      />
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        disabled
        value={values.tour_end_date}
        name="tour_end_date"
        type="date"
        fullWidth
        className="mb-3"
        label="Check Out"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item sm={12} md={4}>
      <Field
        as={TextField}
        name="markup_amount"
        fullWidth
        className="mb-3"
        label={`Markup (${currencies[values?.country]?.[0]?.code || ''})`}
      />
    </Grid>
    <Grid item sm={12}>
      <div className="flex items-center">
        <Typography variant="h6" fontWeight={700}>
          Opt Visa
        </Typography>
        <Checkbox
          disabled={
            !values.country ||
            !allVisas.data.find((x) => x.country === values.country)
          }
          name="visaStatus"
          onChange={(e) => setFieldValue('visaStatus', e.target.checked)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        {values.country &&
          allVisas.data.find((x) => x.country === values.country) && (
            <Typography variant="body1">
              Adult Price:{' '}
              {
                allVisas.data.find((x) => x.country === values.country).price
                  .adult_price
              }
              , Child Price:{' '}
              {
                allVisas.data.find((x) => x.country === values.country).price
                  .child_price
              }
              , Infant Price:{' '}
              {
                allVisas.data.find((x) => x.country === values.country).price
                  .infant_price
              }{' '}
              (Prices in -&nbsp;{currencies[values.country][0].code})
            </Typography>
          )}
      </div>
    </Grid>
  </>
);

export default FormFields;
