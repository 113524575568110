// src/components/EditItinerary/EditItinerary.jsx

import React, { useLayoutEffect, useReducer, useRef } from 'react';
import { Grid, Typography, Divider, Button, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Formik, Form, FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import ModuleHeader from '../moduleHeader/ModuleHeader';
import ActivityItem from './ActivityItem';
import FormFields from './FormFields';
import { reducer, initialState } from './reducer';
import { addDaysToDate } from './utils';
import { TOAST_CONFIG } from './constants';

import countryData from '../../pages/data1.json';
import { useEditItineraryMutation } from '../../apis/itineraryApis/itineraryApi';
import { toast } from 'react-toastify';
import {
  useLazyGetSingleBookingQuery,
  useSendBookingMutation,
} from '../../apis/bookingApis/bookingApi';
import { getAllCurrencies } from '../../app/store/reducers/currencySlice';
import {
  useGetAllLocationsQuery,
  useGetAllVisasQuery,
} from '../../apis/locationsApi/locationsApi';
import { useNavigate } from 'react-router-dom';

const EditItinerary = ({ currentUser, data, activityData, itineraryId }) => {
  const formRef = useRef();
  const checkboxRef = useRef(null);
  const currencies = useSelector(getAllCurrencies);

  const [trigger] = useLazyGetSingleBookingQuery();
  const [sendBooking] = useSendBookingMutation();

  const [editItinerary] = useEditItineraryMutation();
  const { data: allVisas, isLoading: isVisaLoading } = useGetAllVisasQuery();
  const { data: allLocations } = useGetAllLocationsQuery({ keyword: '' });
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer, initialState);

  const INITIAL_VALUES = {
    itineraryName: data.itineraryName,
    description: data.description || '',
    location: data.location || '',
    country: data.country || '',
    NoOfNights: data.days.length - 1,
    visaStatus: data.visaStatus,
    numberOfAdults: data.AdultCount,
    numberOfChildren: data.ChildCount,
    numberOfInfants: data.InfantCount,
    tour_start_date: addDaysToDate(data.startDate, 0),
    tour_end_date: addDaysToDate(data.endDate, 0),
    images: data.images,
    rating: '',
    markup_amount: data?.markup_amount || 0.0,
    hotels: [],
    days: [],
  };



  const handleDurationChange = (e, values) => {
    const nights = parseInt(e.target.value, 10);
    if (nights > 0) {
      const days = nights + 1;
      const existingDays = state.days.length;

      let finalArr;
      if (days > existingDays) {
        const extraDays = days - existingDays;
        const newDays = Array.from({ length: extraDays }, () => [
          {
            activityId: ``,
            activity: {},
            numberOfAdults: values.numberOfAdults,
            numberOfChildren: values.numberOfChildren,
            numberOfInfants: values.numberOfInfants,
            TotalCost: 0.0,
            AdultCost: 0.0,
            ChildCost: 0.0,
            InfantCost: 0.0,
            transportType: '',
          },
        ]);
        finalArr = [...state.days, ...newDays];
      } else {
        finalArr = state.days.slice(0, days);
      }

      const endDate = addDaysToDate(values.tour_start_date, days - 1);
      formRef.current?.setFieldValue('tour_end_date', endDate);
      formRef.current?.setFieldValue('days', finalArr);

      dispatch({
        type: 'CHANGE_DAYS',
        payload: finalArr,
      });
    }
  };

  const updateState = () => {
    const days = parseInt(data.duration, 10);

    const result = Array.from({ length: days }, () => [
      {
        activityId: ``,
        activity: { priceTags: [] },
        numberOfAdults: data.AdultCount,
        numberOfChildren: data.ChildCount,
        numberOfInfants: data.InfantCount,
        TotalCost: 0.0,
        AdultCost: 0.0,
        ChildCost: 0.0,
        InfantCost: 0.0,
        transportType: '',
      },
    ]);

    data.days.forEach((day, dayIndex) => {
      day.forEach((d, dayIdx) => {
        let priceTags = Object.keys(d.price)
          .filter((x) => x !== '_id')
          .map((x) => ({
            name:
              x === 'PT' && d.price[x][0].price > 0
                ? 'Private Transfer'
                : x === 'ST' && parseInt(d.price[x].AdultCost, 10) > 0
                  ? 'Shared Transfer'
                  : x === 'WOT' && parseInt(d.price[x].AdultCost, 10) > 0
                    ? 'Without Transfer'
                    : '',
            type: x,
          }))
          .filter((item) => item.name.length > 0);

        const activityDataState = {
          activityId: d.activityId,
          activity: { priceTags, ...d },
          numberOfAdults: data.AdultCount,
          numberOfChildren: data.ChildCount,
          numberOfInfants: data.InfantCount,
          TotalCost: d.totalCost,
          AdultCost: parseFloat(d.AdultCost),
          ChildCost: parseFloat(d.ChildCost),
          InfantCost: parseFloat(d.InfantCost),
          transportType: d.transportType,
        };

        result[dayIndex][dayIdx] = activityDataState;
      });
    });

    formRef.current?.setFieldValue('days', result);
    dispatch({ type: 'CHANGE_DAYS', payload: result });
  };

  useLayoutEffect(() => {
    updateState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, activityData]);

  return (
    <>
      <ModuleHeader
        headerTitle={data.itineraryName}
        headerImg={data.images[0]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4">
            <LocationOnIcon /> Location: {data.location} - {data.country}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={async (values, actions) => {
          const reqBody = _.cloneDeep(values);
          let total_amount = 0.0;
          let total_adult_amount = 0.0;
          let total_child_amount = 0.0;
          let total_infant_amount = 0.0;

          reqBody.days.forEach((day, index) => {
            day.forEach((d, idx) => {
              d.activityId = state.days[index][idx].activity._id;
              d.numberOfAdults = state.days[index][idx].numberOfAdults;
              d.numberOfChildren = state.days[index][idx].numberOfChildren;
              d.numberOfInfants = state.days[index][idx].numberOfInfants;
              total_amount +=
                parseFloat(state.days[index][idx].TotalCost) || 0.0;
              total_adult_amount +=
                parseFloat(state.days[index][idx].AdultCost) || 0.0;
              total_child_amount +=
                parseFloat(state.days[index][idx].ChildCost) || 0.0;
              total_infant_amount +=
                parseFloat(state.days[index][idx].InfantCost) || 0.0;
              d.totalCost = parseFloat(
                state.days[index][idx].TotalCost || 0.0,
              ).toFixed(2);
              d.AdultCost = parseFloat(
                state.days[index][idx].AdultCost,
              ).toFixed(2);
              d.ChildCost = parseFloat(
                state.days[index][idx].ChildCost,
              ).toFixed(2);
              d.InfantCost = parseFloat(
                state.days[index][idx].InfantCost,
              ).toFixed(2);
              d.activity = {};
            });
          });

          reqBody.total_amount =
            parseFloat(total_amount) + parseFloat(reqBody.markup_amount);
          reqBody.total_adult_amount = parseFloat(
            total_adult_amount || 0.0,
          ).toFixed(2);
          reqBody.total_child_amount = parseFloat(
            total_child_amount || 0.0,
          ).toFixed(2);
          reqBody.total_infant_amount = parseFloat(
            total_infant_amount || 0.0,
          ).toFixed(2);

          if (values.visaStatus) {
            const visa = allVisas.data.find(
              (x) => x.country === values.country,
            );
            reqBody.locationVisaPrice = visa ? visa.price : undefined;
          }

          try {
            const res = await editItinerary({
              id: itineraryId,
              body: reqBody,
            }).unwrap();

            toast.success(
              `Itinerary: ${values.itineraryName} Changed Successfully`,
              TOAST_CONFIG,
            );

            if (!res.hasOwnProperty('custom') || res.custom === true) {
              const bookingRes = await trigger(res.data._id).unwrap();
              const location = allLocations.data.find(
                (x) => x.locationName === values.location,
              );

              await sendBooking({
                pdfContent: {
                  ...res.data,
                  itineraryInfo: bookingRes,
                  currency: currencies[values.country][0].code,
                  document_required: location.document_required,
                  important_note: location.important_note,
                  terms_n_conditions: location.terms_n_conditions,
                  exclusion: location.exclusion,
                  ...(values.visaStatus && {
                    visa: allVisas.data.find(
                      (x) => x.country === values.country,
                    ),
                  }),
                },
                Id: currentUser.userId,
              }).unwrap();

              actions.setSubmitting(false);
              navigate('/dashboard/itineraries');
              toast.success(`Sent Mail Successfully`, TOAST_CONFIG);
            } else {
              actions.setSubmitting(false);
            }
          } catch (err) {
            actions.setSubmitting(false);
            toast.error(`Error Occured`, TOAST_CONFIG);
            console.error(err);
          }
        }}
        innerRef={formRef}
      >
        {({
          values,
          handleChange: formikHandleChange,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <Grid container spacing={3}>
              <FormFields
                values={values}
                handleChange={formikHandleChange}
                setFieldValue={setFieldValue}
                allCountries={{ data: countryData }}
                allLocations={allLocations}
                allVisas={allVisas}
                currencies={currencies}
                dispatch={dispatch}
              />
            </Grid>
            <Divider sx={{ marginY: '0.2rem' }} />
            <Grid container rowSpacing={2}>
              <FieldArray name="days">
                {({ push, insert, remove }) => (
                  <>
                    <Grid item sm={8}>
                      <Typography variant="h6" fontWeight={700}>
                        Add Days
                      </Typography>
                    </Grid>
                    {values.days.map((day, dayIndex) => (
                      <Grid item sm={12} key={dayIndex}>
                        <FieldArray name={`days.${dayIndex}`}>
                          {({ push: pushActivity, pop: popActivity }) => (
                            <Box
                              sx={{
                                background: '#EBE4D1',
                                padding: '0.5rem',
                                borderRadius: '12px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                }}
                              >
                                <Box sx={{ display: 'flex' }}>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    onClick={() => {
                                      const data = {
                                        activityId: ``,
                                        activity: {},
                                        numberOfAdults: values.numberOfAdults,
                                        numberOfChildren:
                                          values.numberOfChildren,
                                        numberOfInfants: values.numberOfInfants,
                                        TotalCost: 0.0,
                                        AdultCost: 0.0,
                                        ChildCost: 0.0,
                                        InfantCost: 0.0,
                                        transportType: '',
                                      };
                                      dispatch({
                                        type: 'ADD_ACTIVITY',
                                        payload: {
                                          index: dayIndex,
                                          data,
                                        },
                                      });
                                      pushActivity(data);
                                    }}
                                  >
                                    + Activity
                                  </Button>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                      if (state.days[dayIndex].length > 1) {
                                        dispatch({
                                          type: 'REMOVE_ACTIVITY',
                                          payload: { index: dayIndex },
                                        });
                                        popActivity();
                                      }
                                    }}
                                  >
                                    Remove
                                  </Button>
                                </Box>
                              </Box>
                              {values.days[dayIndex].map(
                                (activity, activityIndex) => (
                                  <ActivityItem
                                    key={activityIndex}
                                    dayIndex={dayIndex}
                                    activityIndex={activityIndex}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                    activityData={activityData.data}
                                    state={state}
                                    dispatch={dispatch}
                                    currencies={currencies}
                                    country={values.country}
                                  />
                                ),
                              )}
                            </Box>
                          )}
                        </FieldArray>
                      </Grid>
                    ))}
                  </>
                )}
              </FieldArray>
            </Grid>
            <Divider sx={{ marginY: '0.7rem' }} />
            <Button
              type="submit"
              variant="contained"
              disableElevation
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditItinerary;
