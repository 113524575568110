import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import BaseTable from './baseTable/BaseTable';
import { createColumnHelper } from '@tanstack/react-table';
import OptionsDropdown from './optionsDropdown/OptionsDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useRef } from 'react';
import {
  useConfirmQuotationMutation,
  useDeleteBookingMutation,
  useLazyGetSingleBookingQuery,
  useSendBookingMutation,
  useConfirmBookingMutation,
} from '../apis/bookingApis/bookingApi';
import {
  useCreateOrderMutation,
  useVerifyPaymentMutation,
} from '../apis/paymentApis/paymentApis';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal } from '@mui/material';
import BookingPreview from './bookingPreview/BookingPreview';
import {
  useGetAllLocationsQuery,
  useGetAllVisasQuery,
} from '../apis/locationsApi/locationsApi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllCurrencies } from '../app/store/reducers/currencySlice';
import ConfirmationModal from './BookingModal/BookingModal';
import QuotationPreview from './QuotationPreview';
import QuotationPreviewWithOutPrice from './QuotationPreviewWithOutPrice';

const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

const formateDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

export const PreviewModal = forwardRef(({ children }, ref) => {
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    handleOpen() {
      setOpen(true);
    },
  }));

  function handleClose() {
    setOpen(false);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minWidth: 400,
    minHeight: 500,
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 0,
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{children}</Box>
      </Modal>
    </>
  );
});

const QuotationTable = ({
  data,
  currentUser,
  pageSize,
  pageIndex,
  setPagination,
}) => {
  const navigate = useNavigate();
  const currencies = useSelector(getAllCurrencies);
  const { data: allLocations } = useGetAllLocationsQuery({ keyword: '' });
  const { data: allVisas } = useGetAllVisasQuery();

  const modalRef = useRef();
  const quotationModalRef = useRef();
  const quotationModalRefWOP = useRef();

  const [bookingData, setBookingData] = useState({});
  const [previewData, setPreviewData] = useState({});
  const [previewDataWOP, setPreviewDataWOP] = useState({});
  const [modalFlag, setModalFlag] = useState(0);
  const [confirmModalOpen, SetConfirmModalOpen] = useState(false);

  const [sendBooking] = useSendBookingMutation();
  const [confirmQuotation] = useConfirmQuotationMutation();
  const [deleteBooking] = useDeleteBookingMutation();
  const [createOrder] = useCreateOrderMutation();
  const [verifyPayment] = useVerifyPaymentMutation();
  const [confirmBooking] = useConfirmBookingMutation();

  const convertToPdf = async (res) => {
    if (bookingData) {
      const location = allLocations.data.find(
        (x) => x.locationName === res.location,
      );
      const resultData = {
        ...bookingData,
        itineraryInfo: res,
        currency: bookingData.location_data.currencyCode,
        document_required: location.document_required,
        important_note: location.important_note,
        terms_n_conditions: location.terms_n_conditions,
        exclusion: location.exclusion,
        ...(bookingData.visaStatus === true && {
          visa: allVisas.data.find((x) => x.country === res.country),
        }),
      };
      if (modalFlag === 0) {
        setPreviewData(resultData); // Booking Preview
        modalRef.current.handleOpen();
      } else if (modalFlag === 1) {
        setPreviewData(resultData); // Quotation with Price
        quotationModalRef.current.handleOpen();
      } else if (modalFlag === 2) {
        setPreviewDataWOP(resultData); // Quotation without Price
        quotationModalRefWOP.current.handleOpen();
      }
    }
  };

  const handleRedirect = (itineraryId) => {
    const viewUrl = `view/${itineraryId}`;
    navigate(viewUrl);
  };
  const handleEdit = (itineraryId) => {
    const viewUrl = `edit/${itineraryId}`;
    navigate(viewUrl);
  };
  const handleDelete = async (Id) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this itinerary? It will also affect your booking.',
    );
    if (confirmDelete) {
      await deleteBooking(Id)
        .unwrap()
        .then(() => {
          console.log(`Itinerary with ID ${Id} deleted successfully.`);
        })
        .catch((error) => {
          console.log(`Failed to delete Itinerary with ID ${Id}:`, error);
        });
    }
  };

  const [trigger, result] = useLazyGetSingleBookingQuery();

  useEffect(() => {
    result.status === 'fulfilled' && convertToPdf(result.data);
  }, [result]);

  const handleMarkConfirmed = async (bookingData) => {
    await confirmQuotation({ id: bookingData._id.toString() })
      .unwrap()
      .then(async () => {
        const { data: itineary_data } = await trigger(bookingData._id);
        // console.log('FROM TRIGGER', itineary_data);
        const location = allLocations.data.find(
          (x) => x.locationName === itineary_data.location,
        );
        await sendBooking({
          pdfContent: {
            ...bookingData,
            itineraryInfo: itineary_data,
            currency: currencies[itineary_data.country][0].code,
            document_required: location.document_required,
            important_note: location.important_note,
            terms_n_conditions: location.terms_n_conditions,
            exclusion: location.exclusion,
            ...(bookingData.visaStatus === true && {
              visa: allVisas.data.find(
                (x) => x.country === itineary_data.country,
              ),
            }),
          },
          Id: currentUser.userId,
          is_confirmed: true,
        })
          .unwrap()
          .then((res) => {
            toast.success(
              `Booking Confirmed! Sent Mail Successfully`,
              TOAST_CONFIG,
            );
          })
          .catch((err) => {
            toast.error(`Error Occured`, TOAST_CONFIG);
            console.error(err);
          });
      })
      .catch((error) => {
        console.log(
          `Failed to confirm booking with ID ${bookingData._id}:`,
          error,
        );
      });
  };

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor((row, index) => index + 1 + pageIndex * pageSize, {
      header: '#',
      id: 'serialNumber',
    }),
    columnHelper.accessor('bill_no', {
      header: 'Quotation Id',
      cell: (info) => {
        const row = info.row.original;
        // console.log(row);
        const hasHotel = Array.isArray(row.hotels) && row.hotels.length > 0;
        return (
          <div className="flex items-center gap-2">
            <span>{row.bill_no}</span>
            {hasHotel ? (
              <span className="px-0.5 py-0.5 text-xs text-green-800 bg-green-100 rounded-sm">
                Hotel add
              </span>
            ) : (
              <span className="px-0.5 py-0.5 text-xs text-red-800 bg-red-100 rounded-sm">
                Hotel not add
              </span>
            )}
          </div>
        );
      },
    }),

    columnHelper.accessor(
      (row) => row.bookingName || row.itineraryName,
      {
        header: 'Itinerary Name',
        id: 'itineraryName',
      },
    ),
    columnHelper.accessor('created_by', { header: 'Created By' }),
    columnHelper.accessor(
      (row) =>
        row.location_data.currencyCode +
        ' ' +
        parseFloat(row.total_amount).toFixed(2),
      {
        header: 'Total Amount',
        id: 'total_amount',
      },
    ),
    columnHelper.accessor((row) => formateDate(row.tour_start_date), {
      header: 'Start',
      id: 'tour_start_date',
    }),
    columnHelper.accessor((row) => formateDate(row.tour_end_date), {
      header: 'End',
      id: 'tour_end_date',
    }),
    columnHelper.accessor((row) => formateDate(row.created_at), {
      header: 'Booking date',
      id: 'created_at',
    }),
    columnHelper.accessor('_id', {
      header: 'Options',
      cell: (info) => {
        const optionsArr = [
          {
            name: 'View',
            onClickHandler: () =>
              handleRedirect(info.row.original.itinerary_id),
          },
          {
            name: 'Book Hotel',
            onClickHandler: () =>
              navigate(`bookhotel/${info.row.original.itinerary_id}`),
          },
          {
            name: 'Download',
            onClickHandler: () => {
              setModalFlag(0);
              setBookingData(info.row.original);
              trigger(info.row.original._id);
            },
          },
          {
            name: 'Download Quotation With Price',
            onClickHandler: () => {
              setModalFlag(1);
              setBookingData(info.row.original);
              trigger(info.row.original._id);
            },
          },
          {
            name: 'Download Quotation WithOut Price',
            onClickHandler: () => {
              setModalFlag(2);
              setBookingData(info.row.original);
              trigger(info.row.original._id);
            },
          },
          {
            name: 'Edit',
            onClickHandler: () => handleEdit(info.row.original.itinerary_id),
          },
          {
            name: 'Mark Confirmed',
            onClickHandler: () => {
              setBookingData(info.row.original);
              SetConfirmModalOpen(true);
            },
          },
          {
            name: 'Delete',
            onClickHandler: () => handleDelete(info.getValue().toString()),
          },
        ];
        return (
          <OptionsDropdown actions={optionsArr}>
            <MoreVertIcon />
          </OptionsDropdown>
        );
      },
    }),
  ];

  const handleConfirmModalClose = () => {
    SetConfirmModalOpen(false);
  };

  const handleConfirmModalProceed = () => {
    handleMarkConfirmed(bookingData);
    SetConfirmModalOpen(false);
  };

  const COLUMNS = useMemo(() => columns, []);
  return (
    <>
      <PreviewModal ref={modalRef}>
        <BookingPreview data={previewData} />
      </PreviewModal>
      <PreviewModal ref={quotationModalRef}>
        <QuotationPreview data={previewData} />
      </PreviewModal>
      <PreviewModal ref={quotationModalRefWOP}>
        <QuotationPreviewWithOutPrice data={previewDataWOP} />
      </PreviewModal>
      <BaseTable
        data={data.data}
        total_count={data.count}
        columns={COLUMNS}
        previewData={previewData}
        previewDataWOP={previewDataWOP}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        noPaginate={false}
      />
      <ConfirmationModal
        open={confirmModalOpen}
        handleClose={handleConfirmModalClose}
        handleConfirm={handleConfirmModalProceed}
      />
    </>
  );
};

export default QuotationTable;
